<template>
  <v-row>
    <v-col cols="12" sm="12" class="d-none d-md-block">
      <div class="social-sec padding-top">
        <a
          class="main-color icon"
          :href="socialChannels.facebook_link"
          v-if="socialChannels.facebook_link"
          target="_blank"
          ><i class="fab fa-facebook-square" :title="$t('Facebook')"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.instagram_link"
          v-if="socialChannels.instagram_link"
          target="_blank"
          ><i class="fab fa-instagram" :title="$t('Instagram')"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.youtube_link"
          v-if="socialChannels.youtube_link"
          target="_blank"
          ><i class="fab fa-youtube" :title="$t('Youtube')"></i
        ></a>
        <!-- <span class="main-color icon" v-if="socialChannels.instagram_link"
                ><i class="fab fa-facebook-square"></i
              ></span> -->
        <a
          class="main-color icon"
          :href="socialChannels.linkedin_link"
          v-if="socialChannels.linkedin_link"
          target="_blank"
          ><i class="fab fa-linkedin" :title="$t('linkedin')"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.twitter_link"
          v-if="socialChannels.twitter_link"
          target="_blank"
          ><i class="fab fa-twitter" :title="$t('Twitter')"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.whatsapp_link"
          v-if="socialChannels.whatsapp_link"
          target="_blank"
          :title="$t('Whatsapp')"
          ><i class="fab fa-whatsapp"></i
        ></a>
      </div>
    </v-col>
    <v-col cols="12" sm="12" class="d-block d-md-none">
      <div class="social-sec padding-top">
        <a
          class="main-color icon"
          :href="socialChannels.mobile_facebook_link"
          v-if="socialChannels.mobile_facebook_link"
          target="_blank"
          ><i class="fab fa-facebook-square" :title="$t('Facebook')"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.mobile_instagram_link"
          v-if="socialChannels.mobile_instagram_link"
          target="_blank"
          ><i class="fab fa-instagram"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.mobile_youtube_link"
          v-if="socialChannels.mobile_youtube_link"
          target="_blank"
          ><i class="fab fa-youtube" :title="$t('Youtube')"></i
        ></a>
        <!-- <span class="main-color icon" v-if="socialChannels.instagram_link"
                ><i class="fab fa-facebook-square"></i
              ></span> -->
        <a
          class="main-color icon"
          :href="socialChannels.mobile_linkedin_link"
          v-if="socialChannels.mobile_linkedin_link"
          target="_blank"
          ><i class="fab fa-linkedin" :title="$t('linkedin')"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.mobile_twitter_link"
          v-if="socialChannels.mobile_twitter_link"
          target="_blank"
          ><i class="fab fa-twitter" :title="$t('Twitter')"></i
        ></a>
        <a
          class="main-color icon"
          :href="socialChannels.whatsapp_link"
          v-if="socialChannels.whatsapp_link"
          target="_blank"
          ><i class="fab fa-whatsapp" :title="$t('Whatsapp')"></i
        ></a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SocialMedia",
  props: ["socialChannels"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
/* social section */
.social-sec {
  text-align: center;
  .icon {
    font-size: 35px;
    margin: 0 12px;
  }
}
</style>
