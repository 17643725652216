<template>
  <div style="margin: 0 auto">
    <div class="header">
      <div class="actions">
        <v-icon
          :title="$i18n.t('Add New')"
          v-if="this.editable"
          @click="addColumn"
          class="main-color add-btn"
          large
          >add_circle</v-icon
        >
        <v-icon
          v-if="this.editable && !this.editMode"
          @click="editMode = true"
          :title="$t('Edit')"
          >edit</v-icon
        >
      </div>
    </div>
    <v-row v-if="!editable">
      <v-col cols="3">
        <label for="ClassName">{{ $t("Week") }}: </label>
        <v-select
          :items="weeks"
          item-text="name"
          item-value="id"
          v-model.trim="selectedWeekId"
          dense
          outlined
          @change="getSchedules()"
        >
          <template slot="selection" slot-scope="data">
            <!-- HTML that describe how select should render selected items -->
            {{ data.item.name }} {{ data.item.from }} : {{ data.item.to }}
          </template>
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.name }} {{ data.item.from }} : {{ data.item.to }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <!-- <div class="wrapper1">
      <div class="div1"></div>
    </div>
    <div class="wrapper2">
      <div class="div2">
        <table>
          <th>sara</th>
        </table>
      </div>
    </div> -->
    <div
      class="table-container-schadule"
      v-if="
        scheduleLessonsNames.length > 0 &&
        (schedulesFromTo[0].from || !HiddenNoData)
      "
    >
      <div class="child" v-if="scheduleLessonsNames.length > 0">
        <v-form ref="myForm" class="student-form" v-model="valid">
          <table class="scheduleTable">
            <thead>
              <tr class="lesson-header">
                <td class="day-column"></td>
                <td
                  v-for="(lessonName, index) in scheduleLessonsNames"
                  :key="index"
                  class="lesson-td"
                >
                  <div
                    style="text-align: center; margin: 20px"
                    v-if="editMode && editable"
                  >
                    <v-select
                      :items="types"
                      item-text="name"
                      item-value="id"
                      v-model.trim="lessonName.type"
                      solo
                      @change="changeColumnType(index)"
                    >
                    </v-select>
                  </div>
                  <div class="lesson-name">
                    {{ lessonName.name }}
                  </div>
                  <div class="lesson-name" v-if="!lessonName.name && editMode">
                    {{ $t("break") }}
                  </div>
                </td>
              </tr>
              <tr class="time-header">
                <td></td>
                <td
                  v-for="(time, index) in schedulesFromTo"
                  :key="index"
                  class="time-item"
                >
                  <div
                    v-if="editable && editMode"
                    style="text-align: center; margin: 20px"
                  >
                    <v-icon
                      :title="$t('Delete')"
                      style="color: #7195fa"
                      @click="deleteColumn(index)"
                      >{{ "delete" }}</v-icon
                    >
                  </div>

                  <span class="from" v-if="!editMode">{{
                    time.from ? getFullTime(time.from) : $t("From")
                  }}</span>
                  <span class="to" v-if="!editMode">{{
                    time.to ? getFullTime(time.to) : $t("To")
                  }}</span>
                  <timePickerComponent
                    style="width: 45%; float: left"
                    :title="$t('From')"
                    :index="index"
                    :timeVal="time.from"
                    v-if="editMode"
                    v-on:changed="checkAfter"
                  ></timePickerComponent>
                  <timePickerComponent
                    style="width: 45%; float: right"
                    :title="$t('To')"
                    :index="index"
                    :timeVal="time.to"
                    v-if="editMode"
                    v-on:changed="checkAfter"
                  ></timePickerComponent>
                </td>
              </tr>
            </thead>
            <tbody v-for="(schedule, dayIndex) in schedules" :key="dayIndex">
              <tr class="full-day">
                <td class="day">{{ $t(schedule.day) }}</td>
                <td
                  class="lesson-card"
                  v-for="(lessonCard, index) in schedule.lessons"
                  :key="index"
                >
                  <div
                    class="subject"
                    v-if="lessonCard.type == 'lesson' && !editMode"
                  >
                    {{ lessonCard.subject }}
                  </div>
                  <div
                    class="teacher"
                    v-if="lessonCard.type == 'lesson' && !editMode"
                  >
                    {{ lessonCard.teacher }}
                  </div>
                  <div
                    class="teacher"
                    v-if="lessonCard.type == 'lesson' && lessonCard.lesson_id"
                  >
                    <p
                      class="note"
                      :title="lessonCard.class_work_notes"
                      v-if="lessonCard.class_work_notes"
                    >
                      <span style="font-weight: bold"
                        >{{ $t("C.W") }} {{ $t("Notes") }}:</span
                      >
                      {{
                        lessonCard.class_work_notes &&
                        lessonCard.class_work_notes.length >= 16
                          ? lessonCard.class_work_notes.substring(0, 15)
                          : lessonCard.class_work_notes
                      }}
                    </p>
                    <p
                      class="note"
                      :title="lessonCard.home_work_notes"
                      v-if="lessonCard.home_work_notes"
                    >
                      <span style="font-weight: bold"
                        >H.W {{ $t("Notes") }}:</span
                      >
                      {{
                        lessonCard.home_work_notes &&
                        lessonCard.home_work_notes.length >= 16
                          ? lessonCard.home_work_notes.substring(0, 15)
                          : lessonCard.home_work_notes
                      }}
                    </p>
                    <p
                      class="note"
                      :title="lessonCard.notes"
                      v-if="lessonCard.notes"
                    >
                      <span style="font-weight: bold">{{ $t("Notes") }}:</span>
                      {{
                        lessonCard.notes && lessonCard.notes.length >= 16
                          ? lessonCard.notes.substring(0, 15)
                          : lessonCard.notes
                      }}
                    </p>
                  </div>

                  <div
                    v-if="
                      lessonCard.type == 'lesson' &&
                      !editMode &&
                      lessonCard.meeting_active &&
                      canOpenMeeting
                    "
                  >
                    <a :href="FrameLink + lessonCard.meetingId">
                      <img
                        data-v-38104988=""
                        src="../../assets/elearning.svg"
                        width="25"
                      />
                      <strong class="main-color"> {{ $t("Classroom") }}</strong>
                    </a>
                  </div>
                  <div class="break" v-if="lessonCard.type == 'break'">
                    <span>{{ $t("BREAK") }}</span>
                  </div>
                  <div
                    class="subject-container"
                    v-if="editMode && editable && lessonCard.type == 'lesson'"
                  >
                    <label>{{ $t("Subject") }}</label>
                    <v-select
                      :items="lessonCard.subjectItems"
                      item-text="name"
                      item-value="id"
                      v-model.trim="lessonCard.subject_id"
                      solo
                      @change="getTeachers(dayIndex, index)"
                    >
                    </v-select>
                  </div>
                  <div
                    class="teachers-container"
                    v-if="editMode && editable && lessonCard.type == 'lesson'"
                  >
                    <label>{{ $t("Teacher") }}</label>
                    <v-select
                      :items="lessonCard.teacherItems"
                      item-text="name"
                      item-value="id"
                      v-model.trim="lessonCard.teacher_id"
                      solo
                    >
                      <!-- :rules="[
                        checkIfSubjectSelected(
                          lessonCard.subject_id,
                          lessonCard.teacher_id
                        ),
                      ]" -->
                    </v-select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </v-form>
      </div>
    </div>
    <div v-else class="no-data text-center">
      <p v-if="loading">
        {{ $t("Loading Data..") }}
      </p>
      <p v-else>
        {{ $t("No data available") }}
      </p>
    </div>
    <div class="footer" v-if="editable && editMode">
      <v-btn class="modal-btn-cancel" @click="cancelEdit">{{
        $t("Cancel")
      }}</v-btn>
      <v-btn
        class="modal-btn-save"
        @click="saveSchedule"
        :loading="loading"
        :disabled="loading"
        >{{ $t("Save") }}</v-btn
      >
    </div>
    <v-snackbar
      v-model="snackbar"
      timeout="4000"
      color="red"
      :multi-line="true"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ errormsg }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
import { scheduleMixin } from "../../mixins/schedules/scheduleMixin";
import { validationMixin } from "../../mixins/validationMixin";
import timePickerComponent from "./timePickerComponent";
import ACL from "../../acl";
export default {
  mixins: [scheduleMixin, validationMixin],
  props: ["editable", "HiddenNoData"],
  data() {
    return {
      weeks: [],
      selectedWeekId: null,
      canOpenMeeting:
        ACL.Role("Teacher") || ACL.Role("Student") || ACL.Role("Parent"),
      FrameLink: window.location.origin + "/zoomIframe/",
      schedules: [],
      scheduleLessonsNames: [],
      schedulesFromTo: [],
      editMode: false,
      // academicId: this.$router.currentRoute.params.academicYear,
      // classId: this.$router.currentRoute.params.classId,
      subjects: [],
      teachers: [],
      trash: [],
      loading: false,
      valid: false,
      types: [
        { id: "lesson", name: this.$i18n.t("lesson") },
        { id: "break", name: this.$i18n.t("break") },
      ],
      snackbar: false,
      errormsg: "",
    };
  },
  mounted() {
    // $(function() {
    //   $(".wrapper1").scroll(function() {
    //     $(".wrapper2").scrollLeft($(".wrapper1").scrollLeft());
    //   });
    //   $(".wrapper2").scroll(function() {
    //     $(".wrapper1").scrollLeft($(".wrapper2").scrollLeft());
    //   });
    // });
    // setTimeout(
    // () => {
    this.getSchedules();
    // },
    // this.HiddenNoData ? 500 : 0
    // );
    localStorage.activeTab = 1;

    // this.drawTable();
  },
  methods: {
    checkIfSubjectSelected: (subjectItem, teacherItem) => {
      console.log("subjectItem", subjectItem);
      console.log("teacherItem", teacherItem);
      if (subjectItem && subjectItem != "" && !teacherItem) {
        return "Subject must have a teacher";
      }
      return true;
    },
    getFullTime(time) {
      var type = "";
      var H = time.split(":")[0];
      var M = time.split(":")[1];
      if (H == "00") {
        H = 12;
        type = this.$i18n.t("AM");
      } else if (H == "12") {
        type = this.$i18n.t("PM");
      } else if (parseInt(H) > 12) {
        type = this.$i18n.t("PM");
        H = H % 12;
      } else if (parseInt(H) < 12) {
        type = this.$i18n.t("AM");
      }
      return H + ":" + M + " " + type;
    },
    checkAfter(title, time, index) {
      // console.table([title, time, index]);

      if (title == "From") {
        if (parseInt(index) > 0) {
          if (
            time >= this.schedulesFromTo[parseInt(index) - 1]["to"] &&
            this.schedulesFromTo[parseInt(index) - 1].to
          ) {
            // console.log("from");
            // check from before to
            if (this.schedulesFromTo[index].to) {
              if (time && time >= this.schedulesFromTo[parseInt(index)].to) {
                this.schedulesFromTo[index].from = "";
                if (index != 0) this.schedulesFromTo[index - 1].to = "";
              } else {
                this.schedulesFromTo[index].from = time;
                if (index != 0) this.schedulesFromTo[index - 1].to = time;
              }
            } else {
              this.schedulesFromTo[index].from = time;
              if (index != 0) this.schedulesFromTo[index - 1].to = time;
            }
            if (
              index > 0 &&
              typeof this.schedulesFromTo[parseInt(index - 1)] !== "undefined"
            ) {
              if (this.schedulesFromTo[index].to) {
                if (time < this.schedulesFromTo[index].to) {
                  this.schedulesFromTo[parseInt(index - 1)].to = time;
                }
              } else {
                this.schedulesFromTo[parseInt(index - 1)].to = time;
              }
            }
          } else {
            this.schedulesFromTo[index].from = "";
            if (index != 0) this.schedulesFromTo[index - 1].to = "";
          }
          if (
            this.schedulesFromTo[index - 1].to >
              this.schedulesFromTo[index].from ||
            !this.schedulesFromTo[index - 1].to
          ) {
            this.schedulesFromTo[index - 1].to = "";

            this.schedulesFromTo[index].from = "";
          }
        } else {
          if (
            time > this.schedulesFromTo[index]["to"] &&
            this.schedulesFromTo[index]["to"]
          ) {
            this.schedulesFromTo[index].from = "";
            if (index != 0) this.schedulesFromTo[index - 1].to = "";
          } else {
            this.schedulesFromTo[index].from = time;
            if (index != 0) this.schedulesFromTo[index - 1].to = time;
          }
        }
      } else if (title == "To") {
        if (parseInt(index) > 0) {
          if (time > this.schedulesFromTo[index]["from"]) {
            if (this.schedulesFromTo[index].from) {
              this.schedulesFromTo[index].to = time;
              // if (this.schedulesFromTo.length > index)
              //   this.schedulesFromTo[parseInt(index) + 1].from = time;
            }

            // console.log("to");
            // check from before to
            if (this.schedulesFromTo[index].from) {
              if (time && time <= this.schedulesFromTo[index].from) {
                this.schedulesFromTo[index].to = "";
                if (this.schedulesFromTo.length > index)
                  this.schedulesFromTo[index + 1].from = "";
              }
            }
            if (
              typeof this.schedulesFromTo[parseInt(index + 1)] !==
                "undefined" &&
              this.schedulesFromTo[index].from &&
              time > this.schedulesFromTo[index].from
            ) {
              this.schedulesFromTo[parseInt(index + 1)].from = time;
            }

            if (
              this.schedulesFromTo[parseInt(index + 1)].to &&
              this.schedulesFromTo.length > index &&
              this.schedulesFromTo[index].to >
                this.schedulesFromTo[parseInt(index + 1)].to
            ) {
              this.schedulesFromTo[index].to = "";
              if (this.schedulesFromTo.length > index)
                this.schedulesFromTo[index + 1].from = "";
            }
          } else {
            this.schedulesFromTo[index].to = "";
            if (this.schedulesFromTo.length > index)
              this.schedulesFromTo[parseInt(index) + 1].from = "";
          }
        } else {
          if (
            time < this.schedulesFromTo[index]["from"] ||
            !this.schedulesFromTo[index].from
          ) {
            this.schedulesFromTo[index].to = "";
            if (this.schedulesFromTo.length > index)
              this.schedulesFromTo[parseInt(index) + 1].from = "";
          } else {
            this.schedulesFromTo[index].to = time;
            if (this.schedulesFromTo.length > index)
              this.schedulesFromTo[parseInt(index) + 1].from = time;
          }
          if (
            this.schedulesFromTo.length > index &&
            this.schedulesFromTo[index].to >
              this.schedulesFromTo[parseInt(index + 1)].to &&
            this.schedulesFromTo[parseInt(index + 1)].to
          ) {
            this.schedulesFromTo[index].to = "";
            if (this.schedulesFromTo.length > index)
              this.schedulesFromTo[index + 1].from = "";
          }
        }
      }
    },
  },
  components: {
    timePickerComponent,
  },
};
</script>

<style scoped>
@import "../../assets/css/ScheduleTable.css";
</style>
