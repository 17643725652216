<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="300px"
      min-width="300px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="timeVal"
          solo
          :label="title"
          readonly
          v-on="on"
          :rules="validation_rules"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu2"
        v-model="time"
        full-width
        format="ampm"
        @click:minute="saveTime"
        :required="true"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["title", "index", "timeVal"],
  data() {
    return {
      time: null,
      menu2: false,
      modal2: false,
      validation_rules: [(v) => !!v || this.$i18n.t("Required")],
    };
  },
  methods: {
    saveTime() {
      this.$refs.menu.save(this.time);
      this.$emit("changed", this.title, this.time, this.index);
    },
  },
  // computed:{
  //   timeVal
  // }
};
</script>

<style></style>
