<template>
  <v-row>
    <v-col>
      <div class="announcements-content">
        <div class="pagination" v-if="items.length > 1">
          <div class="content">
            <span class="next">
              <v-btn icon @click="prev()">
                <v-icon v-if="currentAppLocale != 'ar'"
                  >mdi-chevron-left</v-icon
                >
                <v-icon v-else>mdi-chevron-right</v-icon>
              </v-btn>
            </span>
            <span class="number">{{ page }}</span>
            <span class="prev">
              <v-btn icon @click="next()">
                <v-icon v-if="currentAppLocale != 'ar'"
                  >mdi-chevron-right</v-icon
                >
                <v-icon v-else>mdi-chevron-left</v-icon>
              </v-btn>
            </span>
          </div>
        </div>
        <div class="annoucements">
          <!-- <span>
        <v-icon
          aria-hidden="false"
          title="Close"
          @click="closeAnnouncement(index, item.id)"
          class="close"
        >
          mdi-close
        </v-icon></span
      > -->
          <!-- <div class="image"></div> -->
          <div
            v-if="items.length > 0 && items[page - 1].message"
            class="overlay"
          ></div>
          <div
            class="message"
            v-if="items.length > 0 && items[page - 1].message"
          >
            <p v-html="items[page - 1].message"></p>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "annoucementsDashbordComponent",
  data() {
    return {
      items: [],
      page: 1,
    };
  },
  computed: {},
  methods: {
    next() {
      if (this.page < this.items.length) {
        this.page++;
      }
    },
    prev() {
      if (this.page > 1) {
        this.page--;
      }
    },
    // closeAnnouncement(index, id) {
    //   console.log(id);

    //   axios
    //     .get(
    //       this.getApiUrl + "/announcements/userSeenAnnouncement/" + id,

    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.token
    //         }
    //       }
    //     )
    //     .then(response => {
    //       if (response.data.status.error == false) {
    //         this.items.splice(index, 1);
    //       }
    //     });
    // },
    getAnnouncementsUser() {
      axios
        .get(
          this.getApiUrl + "/announcements/getAnnouncementsUser",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.items = response.data.data;
          }
        });
    },
  },

  mounted() {
    this.getAnnouncementsUser();
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";
.announcements-content {
  border: 1px solid #b0c4fc;
  padding: 10px;
  border-radius: 5px;
}
.pagination {
  text-align: right;
  .content {
    width: 100%;
  }
  .number {
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: $main-color;
    text-align: center;
    margin-top: 5px;
    border-radius: 50px;
    padding: 1px;
    color: #ffffff;
  }
}
.annoucements {
  width: 100%;
  position: relative;
  // margin-bottom: 20px;
  min-height: 80px;
  height: 80px;
  overflow: hidden;
  // transition: height 0.5s linear;
  // transition: all 2s;
}
.annoucements:hover,
.annoucements:active {
  overflow: visible;
  height: 100%;
}

.close {
  position: absolute;
  top: -22px;
  right: -14px;
  z-index: 99999;
  color: #7297fe;
  border: 2px solid #7297fe;
  border-radius: 50%;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.message {
  z-index: 2;
  /* padding-top: 13%; */
  position: relative;
  /* text-align: center; */
}
</style>
