<template>
  <v-dialog v-model="HyperPayModel" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Pay") }}</span>
      </v-card-title>
    </v-card>
    <v-card-text>
      <v-container>
        <form
          action=""
          class="paymentWidgets"
          data-brands="VISA MASTER MADA"
          id="FromHyperPay"
        ></form>
      </v-container>
    </v-card-text>
  </v-dialog>
</template>

<script>
export default {
  props: ["HyperPayModel", "actionUrl", "shopperResultUrl"],
  data() {
    return {};
  },
  watch: {
    HyperPayModel: {
      handler() {
        if (this.HyperPayModel == true) {
          this.shopperResultUrl =
            window.location.origin + this.$route.query.callbackUrl;
          // console.log("callback =" + this.$route.query.callbackUrl);

          document.getElementById(
            "FromHyperPay"
          ).action = this.shopperResultUrl;

          this.actionUrl = this.$route.query.actionUrl;
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", this.actionUrl);
          document.head.appendChild(recaptchaScript);
        }
      }
    }
  },
  mounted() {
    this.shopperResultUrl =
      window.location.origin + this.$route.query.callbackUrl;
    // console.log("callback =" + this.$route.query.callbackUrl);

    document.getElementById("FromHyperPay").action = this.shopperResultUrl;

    this.actionUrl = this.$route.query.actionUrl;
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", this.actionUrl);
    document.head.appendChild(recaptchaScript);
  }
};
</script>
