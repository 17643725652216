<template>
  <v-card>
    <v-card-text>
      <!-- Assignments Table -->
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :options.sync="options"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            item-key="id"
            :show-select="false"
            class="elevation-1 level1"
            text="left"
            hide-default-footer
          >
            <template v-slot:top>
              <v-row class="headerTable-div">
                <v-col md="4" sm="12">
                  <h3 class="custom-header main-color">
                    {{ $t("Assignments") }}
                  </h3>
                </v-col>
                <v-col md="4" sm="12">
                  <label class="search" for="inpt_search">
                    <input
                      id="inpt_search"
                      class="input_search"
                      @focus="inputFocus"
                      @blur="inputBlur"
                      type="text"
                      @keyup.enter="getAllData(page)"
                      v-model.trim="search"
                      :title="$t('Search')"
                    />

                    <v-icon
                      title="Search..."
                      @click="getAllData(page)"
                      class="search-icon"
                      :title="$t('Search')"
                      >search</v-icon
                    >
                  </label>

                  <span :title="$t('Filter')">
                    <v-menu
                      v-model="filterMenu"
                      offset-y
                      :close-on-content-click="false"
                      :nudge-width="300"
                      offset-x
                    >
                      <template v-slot:activator="{ on }">
                        <i
                          v-on="on"
                          class="fas fa-filter main-color fa-lg"
                          :title="$t('Filter')"
                        ></i>
                      </template>
                      <!-- <v-list-item class="select" style="">
                      <v-autocomplete
                        v-model="filter.states"
                        label="State"
                        :items="states"
                        @change="hideLabel = true"
                        item-text="name"
                        item-value="id"
                        solo
                      ></v-autocomplete>
                    </v-list-item> -->
                    <v-list-item class="select">
                      <v-autocomplete :label="$t('Quarters')" :items="allQuarters" item-text="name" item-value="id"
                        v-model="filter.quarters" solo></v-autocomplete>
                    </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.teachers"
                          label="Teacher"
                          :items="teachers"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.subjects"
                          label="Subjects"
                          :items="subjects"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                    </v-menu>
                  </span>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <!-- ======================================== Pagination ======================================  -->
          <div class="text-center pt-2">
            <v-pagination
              v-if="length > 0 && totalItems > 0"
              v-model="assignment_page"
              :length="length"
              :circle="circle"
              :page="assignment_page"
              :total-visible="totalVisible"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
      <!-- end Assignments Table -->
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "assignmentsOnParentDB",

  props: {
    selectedStudent_id: {
      require: true,
    },
  },
  data() {
    return {
      filterMenu: "",
      options: {
        itemsPerPage: 15,
      },
      loading: false,
      headers: [
        {
          text: this.$i18n.t("Title"),
          value: "title",
          sortable: false,
        },
        {
          text: this.$i18n.t("Subject"),
          value: "subject",
          sortable: false,
        },

        {
          text: this.$i18n.t("Due Date"),
          value: "due_date",
          sortable: false,
        },
        {
          text: this.$i18n.t("Teacher"),
          value: "teacher",
          sortable: false,
        },
      ],
      items: [],
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      assignment_page: 1,
      search: "",
      filter: {
        teachers: [],
        states: "Active",
        subjects: [],
        quarters:""
      },
      teachers: [],
      subjects: [],
      allQuarters:[],
    };
  },
  watch: {
    "noteDate.date": {
      handler() {
        this.getDayName();
        this.getNotesDataForStudent(this.noteDate.date);
      },
    },
    assignment_page: {
      handler() {
        this.getAllData(this.assignment_page);
      },
      deep: true,
    },
    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.assignment_page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.assignment_page);
      },
      deep: true,
    },
    selectedStudent_id: {
      handler() {
        this.getAllData();
      },
      deep: true,
    },
  },
  methods: {
    getAllData(assignment_page = null) {
      if (assignment_page == null) {
        assignment_page = this.assignment_page;
      }
      axios
        .get(
          this.getApiUrl +
            "/dashboard/parent/getAssignesStudent/" +
            this.selectedStudent_id +
            "?search=" +
            this.search +
            "&page=" +
            assignment_page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          //   this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
          let data = response.data.data.data;
          let teachers = this.teachers;
          let subjects = this.subjects;

          data.forEach((item) => {
            let subjecObj = {
              name: item.subject,
              id: item.academic_grade_subjects_id,
            };
            let teaccObj = {
              name: item.teacher,
              id: item.teacher_id,
            };

            subjects.push(subjecObj);
            teachers.push(teaccObj);
          });

          this.teachers = teachers;
          this.subjects = subjects;
        });
    },
    getQuarters() {
      axios
        .get(
          this.getApiUrl +
          "/exams/filter/getQuartersForFilter",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.allQuarters = response.data.data.quarters;

          //  this.filter.quarters =[];
          this.filter.quarters = response.data.data.currntQuarterid;

        });
    }
  },
  mounted() {
    this.getQuarters();
    setTimeout(() => {
      this.getAllData();
    }, 1000);
  },
};
</script>

<style></style>
