import axios from "axios";

export const DashboardMixin = {
  data() {
    return {
      socialChannels: {
        facebook_link: "",
        instagram_link: "",
        youtube_link: "",
        linkedin_link: "",
        twitter_link: "",
        whatsapp_link: "",
        mobile_facebook_link: "",
        mobile_instagram_link: "",
        mobile_youtube_link: "",
        mobile_linkedin_link: "",
        mobile_twitter_link: "",
        mobile_whatsapp_link: "",
      },
    };
  },
  methods: {
    activateDatePicker() {
      let _this = this;
      $(document).ready(function () {
        $("#note-date").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              _this.noteDate.date = dateM;
            } else {
              _this.noteDate.date = "";
            }
          },
        });
        $("#note-date2").calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateM =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              _this.noteDate.date = dateM;
            } else {
              _this.noteDate.date = "";
            }
          },
        });
      });
    },

    nextPrevDay(dayInput, next) {
      let dateInput = dayInput.split("/");
      let convertedDate =
        dateInput[1] + "/" + dateInput[0] + "/" + dateInput[2];

      let dateValue = new Date(convertedDate);

      let dayAdded = 60 * 60 * 24 * 1000;

      let newDate;
      if (next == true) {
        newDate = new Date(dateValue.getTime() + dayAdded);
      } else if (next == false) {
        newDate = new Date(dateValue.getTime() - dayAdded);
      }

      let month = "" + (newDate.getMonth() + 1);
      let day = "" + newDate.getDate();
      let weekDay = "" + newDate.getDay();
      let year = newDate.getFullYear();

      newDate = day + "/" + month + "/" + year;

      this.noteDate.date = newDate;
      this.getDayName(weekDay);

      if (this.parentDashboard) {
        this.getNotesDataForParent(this.noteDate.date, this.selectedChild);
      } else if (this.teacherDashboard) {
        this.getNotesDataForTeacher(this.noteDate.date, this.selectedClass);
      } else {
        // this.getNotesDataForStudent(this.noteDate.date);
      }
    },

    getTodayDate() {
      let today = new Date();
      let month = "" + (today.getMonth() + 1);
      let day = "" + today.getDate();
      let weekDay = "" + today.getDay();
      let year = today.getFullYear();

      this.noteDate.date = day + "/" + month + "/" + year;
      this.getDayName(weekDay);

      if (!this.parentDashboard && !this.teacherDashboard) {
        // this.getNotesDataForStudent(this.noteDate.date);
      }
    },

    getDayName(weekDay) {
      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      if (weekDay) {
        this.noteDate.day = days[weekDay];
      } else {
        let dateInput = this.noteDate.date.split("/");
        let convertedDate =
          dateInput[1] + "/" + dateInput[0] + "/" + dateInput[2];

        let dateValue = new Date(convertedDate);
        let weekDay = "" + dateValue.getDay();
        this.noteDate.day = days[weekDay];
      }
    },

    getNotesDataForStudent(date) {
      axios
        .post(
          this.getApiUrl + "/dashboard/student/getWeeklyPlanNotes",
          {
            date: date,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.day_notes = response.data.data.day_notes;
            this.lessons_notes = response.data.data.lessons_notes;
            this.vacation = response.data.data.vacation;
            this.not_in_active_year = response.data.data.not_in_active_year;
            this.confirmed = response.data.data.week_confirmed;
          }
        });
    },
    getNotesDataForParent(date, selectedChild) {
      axios
        .post(
          this.getApiUrl + "/dashboard/parent/getChildWeeklyPlanNotes",
          {
            date: date,
            student_id: selectedChild,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.day_notes = response.data.data.day_notes;
            this.lessons_notes = response.data.data.lessons_notes;
            this.vacation = response.data.data.vacation;
            this.not_in_active_year = response.data.data.not_in_active_year;
            this.confirmed = response.data.data.week_confirmed;
          }
        });
    },
    getNotesDataForTeacher(date, selectedClass) {
      axios
        .post(
          this.getApiUrl + "/teachers/getWeeklyPlanNotes",
          {
            date: date,
            class_id: selectedClass,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.day_notes = response.data.data.day_notes;
            this.lessons_notes = response.data.data.lessons_notes;
            this.vacation = response.data.data.vacation;
            this.not_in_active_year = response.data.data.not_in_active_year;
            this.confirmed = response.data.data.week_confirmed;
          }
        });
    },
    getSocialLinks() {
      axios
        .get(this.getApiUrl + "/school/showSocialData/" + localStorage.school, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            if (response.data.data != null) {
              this.socialChannels = response.data.data;
            }
            if (response.data.data && response.data.data.whatsapp_link) {
              this.socialChannels.whatsapp_link =
                "https://api.whatsapp.com/send?phone=" +
                response.data.data.whatsapp_link;
            }
          }
        });
    },
  },
  mounted() {
    this.getSocialLinks();
    this.activateDatePicker();
    if (this.dashbaordType != "admin") {
      this.getTodayDate();
    }
  },
  beforeMount() {
    if (localStorage.login == "true") {
      // this.$router.push("/dashboard");
      if (
        this.$router.currentRoute.query.redirectTo &&
        this.$router.currentRoute.query.redirectTo != undefined
      ) {
        this.$router.push({
          path: `/` + this.$router.currentRoute.query.redirectTo,
        });
      } else {
        if (this.$route.path != "/") {
          this.$router.push({ path: `/` });
        }
      }
    }
  },
};
